import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import ServiceTable from '../components/service-table'
import { Treatment, Question } from '../interfeces'

interface Props {
  data: any
  location: Location
}

const treatment: Treatment = {
  duration: '60 minutes',
  session: '1 session',
  results: 'Results up to 3 weeks',
}

const questions: Array<Question> = [
  {
    question:
      'What is the difference between dermaplaning and luxury dermaplaning?',
    answer:
      'Think of luxury dermplaning as a dermaplane facial. This safe and advanced ' +
      'exfoliation immediately removes dead skin and peach fuzz for brighter, ' +
      'smoother skin. But your treatment shouldn’t stop there - now is the ' +
      'perfect time for an anti-ageing facial. Skincare actives can penetrate ' +
      'far more deeply because they don’t have to get past a thick layer of dead skin. ',
  },
  {
    question: 'What happens during luxury dermaplaning?',
    answer:
      'Firstly, your Ada Aesthetics practitioner will deep cleanse your face, ' +
      'followed by an antiseptic toner to make it spotless. They will gently ' +
      'remove peach fuzz and dead skin cells from the top layer of skin with ' +
      'a scalpel, followed by a relaxing facial to soothe your skin. ' +
      'Enjoy a relaxing facial massage, then choose from a detoxifying,' +
      ' hydrating or firming mask for younger, healthier skin. ' +
      'No downtime – just glowing skin.',
  },
  {
    question: 'Is luxury dermaplaning safe?',
    answer:
      'At Ada Aesthetics, our practitioners are highly trained to safely shave ' +
      'away peach fuzz with a sterile, ultra-fine surgical scalpel.' +
      ' Their short, methodical strokes are so light that you will barely ' +
      'feel it – some even find it relaxing. It is completely pain-free' +
      ' because they only remove the very top skin cells that are already dead.',
  },
]

const Dermaplaning: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Luxury Dermaplaning - Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax titleName="Luxury Dermaplaning" customClass="page_title" />

      <section className="ls service-single s-pt-70 s-pb-20 s-pb-sm-50 s-py-lg-100 s-pt-xl-150 s-pb-xl-100 c-gutter-60 c-mb-50">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="excerpt">
                Does your make-up get trapped in the dark vellus hair on your
                face? Peach fuzz can trap dead skin, oil and block your pores –
                no wonder your skin looks dull and lifeless.
              </p>
              <p>
                If you have a big night out coming up and need to improve the
                texture of your skin quickly, luxury dermaplaning could be the
                answer.
              </p>
              <p>
                At Ada Aesthetics, we are proud to offer luxury Dermaplane
                Facials. This pain-free, advanced exfoliating treatment leaves
                your skin super soft and glowing for up to five weeks. Think
                airbrushed, red-carpet-ready skin in less than an hour. Even
                Cleopatra was a big fan!
              </p>
            </div>
          </div>
          <ServiceTable treatment={treatment} questions={questions} />
        </div>
      </section>
    </Layout>
  )
}

export default Dermaplaning

export const pageQuery = graphql`
  query Dermaplaning {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
